import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import counterReducer from "./CounterSlice/counter";
import userReducer from "./UserSlice/user";
import malaebReducer from "./MalaebSlice/malaeb";
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import newState from "./book"
import { api } from '../services/api';

const reducers = combineReducers({
	api: api.reducer,
	counter: counterReducer, //counterslice
	user: userReducer, //userSlice
	malaeb: malaebReducer,//malaebSlice
	newState //newSlice
});

const persistConfig = {
	key: 'root2',
	storage,
	//   whitelist: ['user']
}
const persistedReducer = persistReducer(persistConfig, reducers)

// const sagaMiddleware = createSagaMiddleware();
// const middlewares = [sagaMiddleware];

const store = configureStore({
	reducer: persistedReducer,
	middleware: getDefaultMiddleware => {
		const middlewares = getDefaultMiddleware({
			serializableCheck: false,
			// {
			//   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			// },
		}).concat(api.middleware)

		return middlewares
	},
})

const persistor = persistStore(store)

setupListeners(store.dispatch)
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = ReturnType<typeof store.dispatch>
export { store, persistor }
