import {  MalaebState } from "./props"


const setNewNumber = (
    state: MalaebState,
    payload: { payload: number },
) => {
    state.newCount =
        payload.payload
}



export default {
    setNewNumber
}
