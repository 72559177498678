import { MalaebState } from './props';
import { LANGS, SPORTS } from '../../Constants';
import { getStorage, removeStorage, setStorage, uuidv4 } from '../../helpers/helpers';

const CURRENT_VERSION = '1.0.0';

export const currentLocale = window.localStorage?.getItem("currentLocale");
export default {
  newCount: 1

} as MalaebState