import React, { Suspense } from 'react';
import Locale from './translations';
import AxiosConfiguration from './axiosConfiguration';
import Routes from './routes';
import { useSelector } from 'react-redux';
import { Loader } from './helpers/helpers';
import { BrowserRouter as Router } from 'react-router-dom';
import { RootState } from './redux/configureStore';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Intercom } from 'components';

function App() {
  const locale = useSelector((state: RootState) => state.malaeb.locale);
  const loading = useSelector((state: RootState) => state.malaeb.loading);
  const locationPath = window.location.pathname;

  Locale.setLanguage(locale);
  document
    .getElementsByTagName('html')[0]
    .setAttribute('lang', locale);

  document
    .getElementsByTagName('html')[0]
    .setAttribute('dir', locale === 'ar' ? 'rtl' : 'ltr');

  AxiosConfiguration();
  return (
    <>
      {locationPath !== "/liveScoring" ?
        <GoogleReCaptchaProvider reCaptchaKey={"6Lfytg4mAAAAAFvGweh44xB8nl7JV-aTTbgQNBZr"}>
          <Intercom />
          {loading && <Loader />}
          <Router>
            <Suspense fallback={<Loader />}>{Routes}</Suspense>
          </Router>
        </GoogleReCaptchaProvider>
        :
        <>
          {loading && <Loader />}
          <Router>
            <Suspense fallback={<Loader />}>{Routes}</Suspense>
          </Router>
        </>
      }
    </>

  );
}

export default App;
