import React from 'react';
import ReactDOM, { hydrate, render } from "react-dom";
import './scss/style.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { store as reduxToolKitStore } from "./redux/configureStore"
import { createRoot } from 'react-dom/client';

Sentry.init({
  dsn: 'https://8717ded4650640838a6093b890f1778a@o321626.ingest.sentry.io/6010402',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  //  hydrate(<App />, rootElement);
  hydrate(
    <React.StrictMode>
      <ReactNotification />
      <HelmetProvider>
        <Provider store={reduxToolKitStore}>
          <Router>
            <App />
          </Router>
        </Provider>
      </HelmetProvider>
    </React.StrictMode>,
    rootElement
  );

} else {
  //  render(<App />, rootElement);

  render(
    <React.StrictMode>
      <ReactNotification />
      <HelmetProvider>
        <Provider store={reduxToolKitStore}>
          <Router>
            <App />
          </Router>
        </Provider>
      </HelmetProvider>
    </React.StrictMode>,
    rootElement
  );

}
reportWebVitals();
