import { LANGS, SPORTS } from '../../Constants';
import { getStorage, removeStorage, setStorage, uuidv4 } from '../../helpers/helpers';

const CURRENT_VERSION = '1.0.0';

function check_version() {
  if (getStorage('version') != CURRENT_VERSION) {
    removeStorage(['user_data']);
    setStorage('version', CURRENT_VERSION);
  }
}
check_version();
//action creators
const INITSITE = 'INITSITE';
export const init_site = (sports_list, countries_list) => ({
  type: INITSITE,
  payload: { sports_list, countries_list },
});

const SELECTED_COUNTRY = 'selected_country';
export const set_selected_country = (country) => ({
  type: SELECTED_COUNTRY,
  payload: { country },
});

const TOP_RATED_STADIUMS = 'top_rated_stadiums';
export const set_top_rated_stadiums = (top_rated_stadiums) => ({
  type: TOP_RATED_STADIUMS,
  payload: { top_rated_stadiums },
});

const SELECTED_COUNTRY_LOCATIONS = 'selected_country_locations';
export const set_selected_country_locations = (country_locations) => ({
  type: SELECTED_COUNTRY_LOCATIONS,
  payload: { country_locations },
});

const SELECTED_CITY_AREAS = 'selected_country_areas';
export const set_selected_city_areas = (city_areas) => ({
  type: SELECTED_CITY_AREAS,
  payload: { city_areas },
});

const SELECTED_LOCATION = 'selected_location';
export const set_selected_location = (selected_location) => ({
  type: SELECTED_LOCATION,
  payload: { selected_location },
});

const UPDATE_FILTER = 'update_filter_action';
export const update_filter = (filter_update) => ({
  type: UPDATE_FILTER,
  sub_filters: filter_update,
});

const UPDATE_SELECTED_SLOT = 'update_selected_slot';
export const update_selected_slot = (slot) => ({
  type: UPDATE_SELECTED_SLOT,
  payload: slot,
});

const UPDATE_USER_DATA = 'update_user_data';
export const setUserData = (user_data) => ({
  type: UPDATE_USER_DATA,
  payload: user_data,
});

const CLEAR_FILTERS = 'clear_all_filters_data';
export const clear_filters = (payload) => ({
  type: CLEAR_FILTERS,
  payload,
});

const SET_LOCALE = 'setLocale';
export const currentLocale = window.localStorage?.getItem("currentLocale");


//state
const initState = {
  loading: false,
  locale: currentLocale ?? "en",
  user_uuid: getStorage('user_uuid') || setStorage('user_uuid', uuidv4()),
  user_data: getStorage('user_data') || {},
  SelectedSlot: {},
  lang: getStorage('lang') || LANGS[1],
  country: getStorage('selected_country'),
  top_rated_stadiums: [],
  country_locations: [],
  city_areas: [],
  selected_location: getStorage('selected_location') || {},
  filters: {
    female_friendly: false,
    order_by_distance: false,
    filter_counter: 0,
    search: '',
    time: '',
    sizes: [],
    sport: SPORTS[0],
    // date: moment().format("YYYY-MM-DD"),
    date: '',
    duration: null,
    is_malaeb_spaces: 0,
    lat: getStorage('lat') || null,
    lng: getStorage('lng') || null,
    distance: getStorage('distance') || 100,
  },
};

//reducers
export default (state = initState, action) => {
  // console.log({ action }, action.type);
  switch (action.type) {
    case SET_LOCALE:
      window.localStorage?.setItem("currentLocale", action.payload);
      window.location.reload();
      return { ...state, locale: action.payload };
    case "setLoading":
      return { ...state, loading: action.payload };
    case UPDATE_USER_DATA:
      // const user_data = {...action.payload.data, token: action.payload.token}
      const user_data = { ...action.payload };
      setStorage('user_data', user_data);
      state = { ...state, user_data };
      break;
    case UPDATE_SELECTED_SLOT:
      state = { ...state, SelectedSlot: action.payload };
      break;
    case INITSITE:
      state = { ...state, ...action.payload };
      break;
    case TOP_RATED_STADIUMS:
      state = { ...state, ...action.payload };
      break;
    case SELECTED_COUNTRY_LOCATIONS:
      state = { ...state, ...action.payload };
      break;
    case SELECTED_COUNTRY:
      setStorage('selected_country', action.payload.country);
      removeStorage(['selected_location', 'lat', 'lng']);
      state = { ...state, ...action.payload };
      break;
    case SELECTED_CITY_AREAS:
      // setStorage("selected_location", action.payload);
      state = { ...state, ...action.payload };
      break;
    case SELECTED_LOCATION:
      const selected_location = action.payload.selected_location;
      if (!selected_location) return state;
      setStorage('selected_location', selected_location);
      setStorage('lat', selected_location.geolat);
      setStorage('lng', selected_location.geolng);
      // setStorage("lat", action.payload.selected_location.lat);
      state = { ...state, ...action.payload };
      state.filters = {
        ...state.filters,
        lat: selected_location.geolat,
        lng: selected_location.geolng,
      };
      break;
    case UPDATE_FILTER:
      let new_filters = { ...state.filters, ...action.sub_filters };
      if (new_filters.lat) setStorage('lat', new_filters.lat);
      if (new_filters.lng) setStorage('lng', new_filters.lng);
      if (new_filters.distance) setStorage('distance', new_filters.distance);
      state = { ...state };
      state.filters = new_filters;
      // case DECREMENT:
      // 	state = { ...state, count: state.count - 1 };
      break;
    case CLEAR_FILTERS:
      state = { ...state, filters: { ...initState.filters, sizes: [] } };
      break;
    default:
      state = state;
  }
  // console.log({ state });
  return state;
};
