import { Route, Switch } from 'react-router-dom';
import React, { lazy } from 'react';

const ListPage = lazy(() => import('./pages/List'));
const HomePage = lazy(() => import('./pages/Home'));
const StadiumPage = lazy(() => import('./pages/Stadium'));
const Error404 = lazy(() => import('./components/Error404/Error404'));
const AboutUs = lazy(() => import('./pages/AboutUs'));
const ContactUs = lazy(() => import('./pages/ContactUs'));
const Faqs = lazy(() => import('./pages/Faqs'));
const Privacy = lazy(() => import('./pages/Privacy'));
const RediretToTournament = lazy(() => import('./pages/TournamentRedirect/RediretToTournament'));
const Events = lazy(() => import('./pages/Events/Events'));
const Terms = lazy(() => import('./pages/Terms'));
const TypeForm = lazy(() => import('./pages/TypeForm/TypeForm'));
const LiveScoring = lazy(() => import('./pages/LiveScoringBoard/LiveScoring'));


const Routes = (
  <Switch>
    <Route exact path="/" component={HomePage} />
    <Route exact path="/list" component={ListPage} />
    <Route exact path="/about" component={AboutUs} />
    <Route exact path="/contact-us" component={ContactUs} />
    <Route exact path="/faqs" component={Faqs} />
    <Route exact path="/terms" component={Terms} />
    <Route exact path="/privacy" component={Privacy} />
    <Route exact path="/tournament/:id" component={RediretToTournament} />
    <Route exact path="/events" component={Events} />
    <Route exact path="/typeForm" component={TypeForm} />
    <Route exact path="/liveScoring" component={LiveScoring} />
    <Route exact path="/:id" component={StadiumPage} />
    <Route exact path="*" component={Error404} />
  </Switch>
);

export default Routes;
